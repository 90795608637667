export default () => (
  <div style={{ display: "flex" }}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="36"
      height="36"
      viewBox="0 0 200 200"
      style={{ marginRight: "0.5rem" }}
      fill="none"
    >
      <path
        d="M100.374 50.7348C100.374 48.3086 100.374 47.0955 100.853 46.5338C101.268 46.0464 101.891 45.7877 102.529 45.838C103.263 45.896 104.119 46.7538 105.83 48.4693L153.416 96.1703C154.603 97.3598 155.196 97.9546 155.418 98.6404C155.614 99.2436 155.614 99.8935 155.418 100.497C155.196 101.183 154.603 101.777 153.416 102.967L105.83 150.668C104.119 152.383 103.263 153.241 102.529 153.299C101.891 153.349 101.268 153.091 100.853 152.603C100.374 152.042 100.374 150.828 100.374 148.402V50.7348Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M118.47 8.46982C108.269 -1.73083 91.7308 -1.73082 81.5301 8.46982L7.65048 82.3494C-2.55016 92.5501 -2.55016 109.089 7.65049 119.289L81.5301 193.169C91.7308 203.37 108.269 203.37 118.47 193.169L192.35 119.289C202.55 109.089 202.55 92.5501 192.35 82.3494L118.47 8.46982ZM109.431 35.2348C104.331 30.1345 96.0613 30.1345 90.961 35.2348L35.5513 90.6445C30.451 95.7449 30.451 104.014 35.5513 109.114L90.961 164.524C96.0613 169.624 104.331 169.624 109.431 164.524L164.841 109.114C169.941 104.014 169.941 95.7449 164.841 90.6445L109.431 35.2348Z"
        fill="black"
      />
    </svg>
    BaseGPT
  </div>
);
